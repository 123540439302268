<template>
  <div>
    <div v-if="!is_detail" style="position: relative">
      <baidu-map
        v-loading="mapLoading"
        class="bm-view"
        :center="center"
        :zoom="zoom"
        :average-center="true"
        @zoomstart="zoomstart"
        @ready="handler"
      >
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" />
        <!--          <bm-label v-for="(marker,index) in markers" :key="index" :content="marker.station_name" :position="{lng: marker.lng, lat: marker.lat}" :label-style="{color: 'red', fontSize : '24px'}" title="Hover me" />-->
        <bml-marker-clusterer :average-center="true">
          <bm-marker
            @click="handle(marker.id)"
            v-for="(marker, index) in markers"
            :key="index"
            :position="{
              lng: marker.lng,
              lat: marker.lat,
            }"
          >
            <bm-label
              :content="marker.station_name"
              :labelStyle="{
                color: 'rgba(0, 155, 255, 1)',
                borderColor: 'rgba(0, 155, 255, 1)',
                fontSize: '12px',
              }"
              :offset="{ width: -35, height: 30 }"
            />
          </bm-marker>
        </bml-marker-clusterer>

        <bm-info-window
          :position="position"
          title="Info Window Title"
          :show="infoWindow.show"
          @close="infoWindowClose"
          @open="infoWindowOpen"
        >
          <p v-text="infoWindow.contents"></p>
        </bm-info-window>
      </baidu-map>

      <!--      <div class="tooltip1 tooltip333 u-flex u-flex-col u-col-top">
        <span v-for="item in table" :key="item.id"
          ><i class="dot" style="background: rgba(0, 156, 255, 1)"></i
          >{{ item.name }}
        </span>
      </div>-->
    </div>
    <!--    <div v-if="is_detail" style="position: relative">
      <baidu-map
        class="bm-view"
        :center="{ lng: table[detail_index].lng, lat: table[detail_index].lat }"
        :zoom="12"
        ak="xck5u2lga9n1bZkiaXIHtMufWXQnVhdx"
        :average-center="true"
        @zoomstart="zoomstart"
        @ready="handler"
      >
        <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" />
        &lt;!&ndash;          <bm-label v-for="(marker,index) in markers" :key="index" :content="marker.station_name" :position="{lng: marker.lng, lat: marker.lat}" :label-style="{color: 'red', fontSize : '24px'}" title="Hover me" />&ndash;&gt;
        <bml-marker-clusterer :average-center="true">
          <bm-marker
            :position="{
              lng: table[detail_index].lng,
              lat: table[detail_index].lat,
            }"
          />
        </bml-marker-clusterer>
      </baidu-map>
      <div class="stationdetail" style="">
        <p>
          <img src="~@/assets/img/fanhui.png" @click="fanhui" />
        </p>
        <div class="stationimg"><img :src="table[detail_index].logo" /></div>
        <div class="imgname">
          <div style="display: flex; align-items: center">
            <em
              v-if="table[detail_index].state === 1"
              class="online_backgroundColor"
              style="margin-right: 5px"
            />
            <em
              v-if="table[detail_index].state === 2"
              class="offline_backgroundColor"
              style="margin-right: 5px"
            />
            <em
              v-if="table[detail_index].state === 3"
              class="fault_backgroundColor"
              style="margin-right: 5px"
            />
            <span style="color: rgb(51, 51, 51)">{{
              table[detail_index].name
            }}</span>
          </div>
          <div style="color: #999">
            <i class="iconfont icon-position" style="font-size: 14px" />
            <span style="font-size: 14px">{{
              table[detail_index].address
            }}</span>
          </div>
        </div>
        <ul class="mapimg af">
          <li>
            <div><img src="~@/assets/img/dangrifadian.png" /></div>
            <div>
              <span>{{ table[detail_index].day_energy.value }}</span>
              <p class="text-size12">
                <span>当日发电</span
                ><span>({{ table[detail_index].day_energy.unit }})</span>
              </p>
            </div>
          </li>
          <li>
            <div><img src="~@/assets/img/guangfu.png" /></div>
            <div>
              <span>{{ table[detail_index].power.value }}</span>
              <p class="text-size12">
                <span>当日功率</span
                ><span>({{ table[detail_index].power.unit }})</span>
              </p>
            </div>
          </li>
          <li>
            <div><img src="~@/assets/img/shandian.png" /></div>
            <div>
              <span>{{ table[detail_index].all_energy.value }}</span>
              <p class="text-size12">
                <span>累计发电</span
                ><span>({{ table[detail_index].all_energy.unit }})</span>
              </p>
            </div>
          </li>
          <li>
            <div><img src="~@/assets/img/dianchi.png" /></div>
            <div>
              <span>{{ table[detail_index].capacity.value }}</span>
              <p class="text-size12">
                <span>组件总容量</span
                ><span>({{ table[detail_index].capacity.unit }})</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>-->
  </div>
</template>

<script>
import { BmlMarkerClusterer } from "vue-baidu-map";

import { getEnergyList, getEnergyListLat } from "@/api/guangfu";

export default {
  name: "baidu",
  components: { BmlMarkerClusterer },
  data() {
    return {
      is_detail: false,
      table: [],
      infoWindow: {
        show: false,
      },
      markers: [],
      mapLoading: false,
      detail_index: 0,
      last_page: 0,
      center: { lng: 0, lat: 0 },
      position: { lng: 0, lat: 0 },
      zoom: 7,
      params: {
        loading: false,
        page: 1,
        page_size: 10,
        order_by: "desc",
        order_key: "created_at",
        searchKey: "",
      },
      total: 0,
      status: 1,
      state: "",
      tongji: {},
    };
  },
  created() {},
  mounted() {
    this.getEnergyList();
  },
  methods: {
    nextPage() {
      if (this.params.page != this.last_page) {
        this.params.page = this.params.page + 1;
        this.getEnergyList();
      }
    },
    infoWindowClose() {
      this.infoWindow.show = false;
    },
    infoWindowOpen() {
      this.infoWindow.show = true;
    },
    previouspage() {
      if (this.params.page != 1) {
        this.params.page--;
        this.getEnergyList();
      }
    },
    handle(id) {
      this.$emit("change", id);
    },
    detail(index) {
      this.is_detail = true;
      this.detail_index = index;
    },
    fanhui() {
      this.is_detail = false;
    },
    getEnergyList() {
      this.params.loading = true;
      this.getEnergyListLat();
      getEnergyList({
        search_key: this.params.searchKey,
        page: this.params.page,
        state: this.state,
        page_size: this.params.page_size,
        order_by: this.params.order_by,
        order_key: this.params.order_key,
      }).then((res) => {
        this.params.loading = false;
        this.table = res.data;
        this.total = res.meta.total;
        this.last_page = res.meta.last_page;
        this.tongji = res.tongji;
      });
    },
    getEnergyListLat() {
      this.mapLoading = true;
      getEnergyListLat({
        state: this.state,
        search_key: this.params.searchKey,
      }).then((res) => {
        this.$nextTick(() => {
          const marker = [];
          res.data.map((item) => {
            marker.push(item);
          });
          this.mapLoading = false;
          this.markers = marker;
          this.zoom = 8;
        });
      });
    },
    zoomstart(event) {
      console.log(event);
    },
    handler({ BMap, map }) {
      console.log(BMap, map);
      map.setMapStyleV2({
        styleId: "8f4efd2eadedfc0aaffb0b9b4cb48960",
      });
      this.getEnergyListLat();

      this.center.lng = 115.011198788294;
      this.center.lat = 34.9252319062479;
    },
  },
};
</script>

<style scoped lang="scss">
.pageNo {
  float: left;
  text-align: center;
  width: 208px;
  height: 40px;
  line-height: 40px;
}

.pages {
  padding: 5% 8%;
  padding-top: 10px;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 11;
  background: #fff;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  .el-button {
    float: left;

    &:last-child {
      float: right;
    }
  }
}

.list {
  position: absolute;
  top: 5%;
  left: 25px;
  background: #fff;
  width: 200px;
  height: 260px;

  li div div {
    height: 24px;
    line-height: 24px;
  }

  .mapspan {
    font-size: 18px;
    color: #333;
    height: 24px;
    display: block;
    width: 205px;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  em {
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin: 5px;
    float: left;
    margin-left: 0;
  }

  ul {
    padding: 5% 8% 68px;
  }

  li {
    height: 60px;
    padding-bottom: 10px;
    border-bottom: 1px solid #f5f5f5;
    cursor: pointer;
    margin-top: 10px;

    img {
      margin: 5px 10px;
      width: 40px;
      height: 40px;
    }
  }

  li > div {
    float: left;
  }
}

.bm-view {
  width: 100%;
  height: 10.3rem;
}

.stationdetail {
  li {
    float: left;
    width: 50%;
    div {
      float: left;
    }
  }
  position: absolute;
  top: 5%;
  left: 25px;
  background: #fff;
  width: 360px;
  height: 560px;
  padding: 1.5%;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  .imgname {
    margin-top: 20px;
    margin-bottom: 25px;
    div:first-child span {
      font-size: 16px;
      color: #333;
      width: 250px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .stationimg {
    img {
      width: 310px;
      height: 290px;
    }
  }
  & > p {
    position: absolute;
    left: 30px;
    top: 30px;
    img {
      width: 25px;
      cursor: pointer;
      margin: 10px;
    }
  }
}
.offline_backgroundColor {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  float: left;
  background-color: #ccc !important;
}

.top-info-item {
  cursor: pointer;
  height: auto;
  padding: 20px 0 0px 25px;

  .title {
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
    font-size: 16px;
  }

  .item-info {
    .item2 {
      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #ffffff;
      }
    }

    .item1 {
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: baseline;
      -webkit-align-items: baseline;
      -moz-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;
      margin-bottom: 10px;

      span:first-child {
        color: #14dbff;
        font-weight: 700;
        line-height: 30px;
        font-size: 30px;
        margin-right: 5px;
      }
      span:last-child {
        font-size: 18px;
        font-weight: 700;
        color: #14dbff;
      }
    }
  }
}

.tooltip333 {
  position: absolute;
  bottom: 0;
  height: 4rem;
  width: 4rem;
}
.mapimg {
  display: flex;
  flex-wrap: wrap;
  li {
    display: flex;
  }
  img {
    margin: 10px 5px 5px 0;
    width: 38px;
    vertical-align: bottom;
  }
}
.mapimg > li > div:last-child {
  width: calc(100% - 5px - 30px);
}
.stationdetail li div:last-child {
  margin-top: 3px;
}
.stationdetail li div {
  float: left;
}
.stationdetail li span {
  color: #5f5d5d;
  font-size: 20px;
}
.stationdetail li p {
  color: #999;
  font-size: 12px;
}
.stationdetail li .text-size12 span {
  font-size: 12px;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 16px;
  color: #333;
}
</style>
